import { useMutation } from '@apollo/client'
import { RECOVER_PASSWORD } from 'lib/graphql/auth'
import PropTypes from 'prop-types'
import React, { useState } from 'react'

import FormButton from '../FormButton'
import Icon from '../Icon'
import Input from '../forms/Input'
import Label from '../forms/Label'

const ResetAccountPasswordForm = ({ customerId, resetToken }) => {
  const [errors, setErrors] = useState([])
  const [isMissMatch, setIsMissMatch] = useState(false)
  const [passwordPayload, setPasswordPayload] = useState({
    password: '',
    confirmPassword: '',
  })
  const { password, confirmPassword } = passwordPayload

  const [resetPassword, { loading, error, data }] = useMutation(RECOVER_PASSWORD, {
    onCompleted: data => {
      setErrors(data.customerRecover.customerUserErrors)
    },
    onError: error => {
      console.log(error)
    },
  })

  const handleChange = e => {
    setPasswordPayload({
      ...passwordPayload,
      [e.target.name]: e.target.value,
    })
  }

  const handleSubmit = () => {
    if (confirmPassword && password !== confirmPassword) {
      return setIsMissMatch(true)
    }
    setIsMissMatch(false)
    setErrors([])
    resetPassword({
      variables: {
        id: `gid://shopify/Customer/${customerId}`,
        input: {
          password,
          resetToken,
        },
      },
    })
  }

  return (
    <div
      css={{
        marginLeft: 'auto',
        marginRight: 'auto',
        maxWidth: '36rem',
        width: '100%',
        minHeight: '80vh',
        padding: '5rem 0',
      }}
    >
      <div
        css={{
          width: '100%',
          backgroundColor: '#fff',
          borderRadius: '0.5rem',
          height: 'auto',
          padding: '3rem',
        }}
      >
        <h3 css={{ fontSize: '3rem', textAlign: 'center' }}>Reset your password</h3>
        <p css={{ textAlign: 'center' }}>Enter a new password for new password.</p>

        <div css={{ margin: '2rem 0' }}>
          <Label for='password'>Password</Label>
          <Input
            name='password'
            type='password'
            placeholder='Enter your password'
            value={password}
            onChange={handleChange}
          />
        </div>
        <div css={{ margin: '2rem 0' }}>
          <Label for='confirmPassword'>Confirm password</Label>
          <Input
            name='confirmPassword'
            type='password'
            placeholder='Confirm your password'
            value={confirmPassword}
            onChange={handleChange}
          />
        </div>
        <FormButton
          onClick={handleSubmit}
          disabled={loading}
          type='submit'
          icon={<Icon.ArrowForward width={16} height={16} />}
        >
          Reset Password
        </FormButton>
        {isMissMatch && (
          <p css={{ color: 'red', textAlign: 'center' }}>
            Password and confirm password do not match
          </p>
        )}
        {errors?.map((e, i) => (
          <p key={i} css={{ color: 'red', textAlign: 'center' }}>
            {e.message}
          </p>
        ))}
      </div>
    </div>
  )
}

ResetAccountPasswordForm.propTypes = {
  customerId: PropTypes.string.isRequired,
  resetToken: PropTypes.string.isRequired,
}

export default ResetAccountPasswordForm
