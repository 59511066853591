import { useLocation } from '@reach/router'
import Container from 'components/Container'
import withIsLoggedIn from 'components/hocs/withIsLoggedIn'
import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet'

import ResetAccountPasswordForm from '../components/auth/ResetAccountPasswordForm'

function ResetAccountPasswordPage() {
  const location = useLocation()
  const urlParams = new URLSearchParams(location.search)
  const customerId = urlParams.get('id')
  const token = urlParams.get('token')

  useEffect(() => {
    if (!customerId || !token) {
      // Show Wrong Token or Customer ID Message
    }
  }, [])

  return (
    <Container css={{ position: 'relative' }}>
      <Helmet title='Reset account password'>
        <meta name='robots' content='noindex' />
      </Helmet>
      <ResetAccountPasswordForm customerId={customerId} resetToken={token} />
    </Container>
  )
}
export default withIsLoggedIn(ResetAccountPasswordPage)
